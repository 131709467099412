<template>
    <div class="page">
        <Part1 />
        <div class="mains">
        <div class="c-left">
            <div class="border"></div>
            <p class="fund">投资组合优化</p>
            <div class="rate">
                <p class="txt">
                    智君科技自主研发的，符合国际顶尖标准的多资产风险因子模型，能够精确地预测各市场共同因子的波动率和相互关系，不仅应用于组合绩效分析与风险管理，同样也是多资产组合构建与优化的基础核心。强大的组合优化器支持用户自定义或使用系统提供的资产收益预测，实现组合整体风险以及在各板块、行业、因子的风险暴露约束，满足一系列个性化的风险和交易偏好，帮助基金经理获得风险调整后最优的绝对收益或超额收益，并提供完整的优化方案和回测报告。
                </p>
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic" v-show="subimg"><img :src="iconimg1" @click="changepic" v-show="!subimg">股票组合优化</p>
                <p class="txt" v-if="showselect">
                  支持多种投资目标和效用函数；支持投资观点与投资偏好的配置；支持多种约束条件，包括组合绝对风险、跟踪误差约束，在各行业、板块、风格因子、具体证券和自定义集合上的风险暴露或波动率的约束，交易费用、换手率等交易条件约束；支持多种概率分布、历史情景或假设情景、多种时间序列模型选择。
                </p>
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic1" v-show="subimg1"><img :src="iconimg1" @click="changepic1" v-show="!subimg1">债券组合优化</p>
                <p class="txt" v-if="showfeng">
                  支持多种投资目标和效用函数；支持多种风险暴露控制，包括在修正久期/凸性、关键期限久期/凸性、利差久期久期/凸性、有效久期久期/凸性，希腊字母（Vega等）的绝对暴露或相对基准暴露控制；支持组合在收益率、利率曲线因子、信用利差、隐含波动率等风险因子的约束；支持多种概率分布、历史情景或假设情景、多种时间序列模型选择。
                </p>
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic2" v-show="subimg2"><img :src="iconimg1" @click="changepic2" v-show="!subimg2">基金组合优化</p>
                <p class="txt" v-if="showyou">
                  支持多种战略和战术配置方法，支持均值-方差、均值-ETL尾部风险约束优化；支持个性化选择风险因子模型，并设置组合在各因子的风险暴露约束；支持多种概率分布、历史情景或假设情景、多种时间序列模型选择。
                </p>
            </div>
        </div>
        <div class="c-right">
            <img src="../../assets/product/投资组合优化.png" alt="" style="width:474px;height:317px">
        </div>
    </div>
        <ApplyForm/>
    </div>
</template>

<script>
import Part1 from './part1'
import ApplyForm from '../../components/ApplyForm'

export default {
    components: { Part1, ApplyForm },
    data() {
        return {
            iconimg:require('../../assets/sub.png'),
            iconimg1:require('../../assets/plug.png'),
            iconimg2:require('../../assets/plug.png'),
            subimg:true,
            subimg1:false,
            subimg2:false,
            showselect:true,
            showfeng:false,
            showyou:false,
        }
    },
    methods:{
        changepic(){
            this.subimg=!this.subimg
            this.showselect=!this.showselect
        },
        changepic1(){
            this.subimg1=!this.subimg1
            this.showfeng=!this.showfeng
        },
        changepic2(){
            this.subimg2=!this.subimg2
            this.showyou=!this.showyou
        }
    }
}
</script>

<style lang="less" scoped>
.mains{
    width:1340px;
    height: 450px;
    margin:0 auto;
    // background:yellow;
    padding:79px 0 90px 0;
    .c-left{
        height:450px;
        // background:red;
        // width:15%;
        float:left;
        margin-left:5.4%;
        .rate{
            width:644px;
            // height:40px;
            padding:0 16px 16px;
            border-bottom:solid 1px #ddd;
        }
        .border{
            width:130px;
            height:8px;
            background:#2E5082;
        }
        .fund{
            // width:308px;
            height:40px;
            font-size:32px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:32px;
            margin:15px 0;
            text-align:left;
        }
        .select{
            width:658px;
            height:136px;
            padding:20px 0 0 10px;
            border-bottom:solid 1px #ddd;
        }
        .txt{
            width:658px;
            // height:80px;
            // float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            text-indent: 28px;
            text-align:left;
            // margin-bottom:74px;
            
        }
        .font16{
            color:#2E5082;
            font-size:16px;
            text-align:left;
            font-weight:600;
        }
        .mark{
                color:#2E5082;
                font-weight:bold;
            }
        .solve{
            // width:180px;
            height:20px;
            float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            // font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            margin-top:19px;
            padding:0;
            a{
                text-decoration: none;
                color:#476CCB;
            }
        }

    }
    .c-right{
        width:474px;
        height:317px;
        float:right;
        margin-right:70px;
    }
    
}
img{
    padding-right:5px;
    margin:0;
}
</style>